<template>
  <div>
    <!-- link without children is clickable itself -->
    <div v-if="!link.children.length" @click="$emit('click:item', link)">
      <FimLink
        :to="localePath(link.href)"
        text-size="md"
        only-exact-active
        bold-on-active
        class="h-12 w-full border-b border-gray-300 pl-[3.25rem] pr-5"
      >
        {{ link.label }}
        <template #iconAfter>
          <LazyIconFielmannArrowRight class="size-4" />
        </template>
      </FimLink>
    </div>
    <div v-else>
      <div class="py-3 pl-[3.25rem] pr-5 font-medium text-gray-500">
        {{ link.label }}
      </div>
      <ul class="border-t border-gray-300">
        <li
          v-for="child in link.children"
          :key="child.slug"
          class="h-12 border-b border-gray-300 pl-[3.25rem] pr-5"
          @click="(event) => $emit('click:item', event)"
        >
          <FimLink
            v-if="!child.children.length && !child.href.includes('://')"
            :id="getFormattedId(child.href)"
            :to="localePath(child.href)"
            only-exact-active
            text-size="md"
            bold-on-active
            class="size-full"
          >
            {{ child.label }}
            <template #iconAfter>
              <LazyIconFielmannArrowRight class="size-4" />
            </template>
          </FimLink>
          <FimButton
            v-else-if="child.href.includes('://')"
            :to="child.href"
            type="link-alternative"
            justify="start"
            no-padding
            class="size-full"
          >
            {{ child.label }}
            <template #iconAfter>
              <LazyIconFielmannArrowRight class="size-4" />
            </template>
          </FimButton>
          <FimButton
            v-else
            type="link-alternative"
            class="size-full"
            justify="start"
            @click="$emit('open-children', child)"
          >
            {{ child.label }}
            <template #iconAfter>
              <LazyIconFielmannArrowRight class="size-4" />
            </template>
          </FimButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NavigationLink } from '~/rpcMethods/navigation'
defineEmits(['click:item', 'open-children'])
defineProps({
  link: {
    type: Object as PropType<NavigationLink>,
    required: true,
  },
})
const localePath = useFimLocalePath()
const getFormattedId = (text: string) => formatId(text, 'menu-item')
</script>
